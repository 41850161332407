<template>
  <div class="d-flex flex-column flex-root justify-content-between">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column justify-content-center align-items-center bg-opalean-dark">
        <div
          class="d-flex flex-column justify-content-center align-items-start --px-20 px-0 px-md-10 --px-lg-20 py-10 h-100 mt-lg-0 mb-lg-40 mt-10 mb-10 w-375px w-lg-550px mx-auto"
        >
          <span class="text-left mb-10 ml-n4">
            <img src="media/opalean/logos/logo-aside-opalean.png" alt="" class="w-60px w-lg-120px" />
          </span>
          <h3 class="font-weight-bolder text-left font-size-h3 display5-lg text-light mb-lg-40 mb-0 line-height-lg" v-translate>
            A simple and intuitive tool that allows a secure tracking of my pallets
          </h3>
          <span class=""></span>
        </div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" id="kt_login_signin_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg" v-translate>Welcome to NeoOpatrace&trade;</h3>
                <span class="text-muted font-weight-bold font-size-h4" v-show="false"
                  ><translate>New Here?</translate>
                  <a id="kt_login_signup" class="text-primary font-weight-bolder" @click="showForm('signup')" v-translate>Create an Account</a></span
                >
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark" v-translate>Email</label>
                <div id="example-input-group-1" label="" label-for="example-input-1">
                  <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="email" ref="email" v-model="form.email" />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5" v-translate>Password</label>
                  <a class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5" id="kt_login_forgot" @click="showForm('forgot')" v-translate
                    >Forgot password ?</a
                  >
                </div>
                <div id="example-input-group-2" label="" label-for="example-input-2">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <span class="label label-inline label-warning mb-1 mr-1" v-if="$store.getters.apiBaseURL != defautApiBaseURL()"
                ><strong class="mr-1">API</strong> {{ $store.getters.apiBaseURL }}</span
              >
              <span class="label label-inline label-secondary mb-1 mr-1" v-if="$store.getters.apiBaseURL != defautApiBaseURL()"
                ><strong class="mr-1">API</strong> Default : {{ defautApiBaseURL() }}</span
              >
              <div class="pb-lg-0 pb-5 d-flex justify-content-center">
                <button ref="kt_login_signin_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-25 py-4 my-3 mr-3" v-translate>Sign in</button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form class="form" novalidate="novalidate" id="kt_login_signup_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg" v-translate>Sign up</h3>
                <p class="text-muted font-weight-bold font-size-h4" v-translate>Enter your details to create your account</p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Fullname"
                  name="fullname"
                  ref="fullname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Confirm password"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  <translate>I agree the</translate>
                  <a href="#terms" class="ml-2"><translate>terms and conditions</translate></a
                  >.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width: 150px"
                  v-translate
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                  v-translate
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form class="form" novalidate="novalidate" id="kt_login_forgot_form" ref="kt_login_forgot_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg" v-translate>Forgotten Password ?</h3>
                <p class="text-muted font-weight-bold font-size-h4" v-translate>Enter your email to reset your password</p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button type="button" id="kt_login_forgot_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4" v-translate>
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                  v-translate
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
          <a href="#terms" class="text-primary font-weight-bolder font-size-h5" v-translate>Terms</a>
          <a href="#contactus" class="text-primary ml-10 font-weight-bolder font-size-h5" v-translate>Contact us</a>
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import Vue from "vue";
var vm = new Vue();

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
//import Swal from "sweetalert2";
import storage from "@/core/services/storage.service";

export default {
  name: "login",
  data() {
    return {
      state: "signin",
      // Default login info
      form: {
        email: "", //jripac
        password: "", //A5599z
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser", "isAuthenticated"]),

    // backgroundImage() {
    //   return (
    //     process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
    //   );
    // }
  },
  mounted() {
    console.info("Login.vue");

    // Now in main.js
    console.error("Login.vue::Mounted::Now in main or not ?? ");

    if (storage.isAuthenticated()) {
      console.log("Login.vue::Mounted::User is authenticated, so route");
      this.$router.push({ name: "route.home" }).catch((failure) => {
        if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
          // show a small notification to the user
          alert("Login in order to access the dashboard");
        }
      });
    }

    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: vm.$gettext("Username is required"),
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: vm.$gettext("Password is required"),
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: vm.$gettext("Full name is required"),
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: vm.$gettext("Email is required"),
            },
            emailAddress: {
              message: vm.$gettext("The value is not a valid email address"),
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: vm.$gettext("Password is required"),
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: vm.$gettext("Confirm password is required"),
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: vm.$gettext("The password and its confirm are not the same"),
            },
          },
        },
        agree: {
          validators: {
            notEmpty: {
              message: vm.$gettext("You should agree terms and conditions"),
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: vm.$gettext("Email is required"),
            },
            emailAddress: {
              message: vm.$gettext("The value is not a valid email address"),
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      // this.$store.dispatch(LOGOUT);

      // @Justin :: ici besoin de verifier si loggued ?
      // this.$store.dispatch(VERIFY_AUTH);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { user: email, pass: password })
          // go to which page after successfully login
          .then((r) => {
            console.log("Login.vue:: GET LOGIN", r, this.$route.query.redirect);
            if (this.$route.query.redirect !== undefined) this.$router.push({ path: this.$route.query.redirect });
            else this.$router.push({ name: "route.home" });
          })
          .catch((e) => {
            console.log("Login.vue:: LOGIN CATCH ERROR", e);

            // Special login error fire
            // @Wilhem / @Justin : ICI je ne parviens pas a recuperer le code erreur
            // Swal.fire({
            //   position: "top-end",
            //   title: vm.$gettext("Login failed"),
            //   text: e,
            //   icon: "error",
            //   confirmButtonClass: "btn btn-secondary",
            //   heightAuto: false,
            // });
          });

        submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
      }, 2000);
    });

    this.fv.on("core.form.invalid", () => {
      window.swalAlert.fire({
        title: vm.$gettext("Login failed"),
        text: vm.$gettext("Please, provide correct credentials!"),
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    // this.fv1.on("core.form.valid", () => {
    //   const email = this.$refs.remail.value;
    //   const password = this.$refs.rpassword.value;

    //   // clear existing errors
    //   this.$store.dispatch(LOGOUT);

    //   // set spinner to submit button
    //   const submitButton = this.$refs["kt_login_signup_submit"];
    //   submitButton.classList.add("spinner", "spinner-light", "spinner-right");

    //   // dummy delay
    //   setTimeout(() => {
    //     // send register request
    //     this.$store
    //       .dispatch(REGISTER, {
    //         email: email,
    //         password: password
    //       })
    //       .then(() => this.$router.push({ name: "route.dashboard" }));

    //     submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
    //   }, 2000);
    // });

    // this.fv1.on("core.form.invalid", () => {
    //   Swal.fire({
    //     title: "",
    //     text: vm.$gettext("Please, provide correct data!"),
    //     icon: "error",
    //     confirmButtonClass: "btn btn-secondary",
    //     heightAuto: false
    //   });
    // });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(KTUtil.getById(form_name), "animate__animated animate__backInUp");
    },
    defautApiBaseURL() {
      return process.env.VUE_APP_API_BASE_URL;
    },
  },
};
</script>
